import { MessageType } from '#core/types/packages/socket'

export const useNotificationRealtimeContext = () => {
  const { socket } = useSocket()
  const messageHandler = ref()
  const { setUnreadNotificationCount } = useNotificationSharedState()

  const subscribe = (userId: string) => {
    logger.log('[Socket] Subscribing to user', userId)

    socket.value?.send({
      type: MessageType.Subscribe,
      payload: {
        modelId: userId,
        modelType: 'User',
      },
    })
    messageHandler.value = socket.value?.on('message', (message) => {
      if (message.type === 'notification') {
        const count = message.payload?.unreadCount || 0
        setUnreadNotificationCount(count)
      }
    })
  }

  const unsubscribe = (userId: string) => {
    logger.log('[Socket] Unsubscribing to user', userId)

    messageHandler.value?.unsubscribe()
    socket.value?.send({
      type: MessageType.Unsubscribe,
      payload: {
        modelId: userId,
        modelType: 'User',
      },
    })
  }

  return {
    subscribe,
    unsubscribe,
  }
}
