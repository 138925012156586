<template>
  <UModal v-model="isOpen" :ui="{ base: 'min-w-[60svw]' }">
    <UCard :ui="{ body: { padding: '!px-0' }, ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex justify-between gap-3">
          <div class="flex gap-3 items-center">
            <h1 class="font-semibold text-xl">Keyboard shortcuts</h1>
            <div class="flex gap-1">
              <UKbd size="md">{{ metaSymbol }}</UKbd>
              <UKbd size="md">.</UKbd>
            </div>
          </div>
          <UButton
            color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid"
            @click="isOpen = false"
          />
        </div>
      </template>
      <div class="grid grid-cols-2 gap-10 max-h-[70svh] w-full overflow-y-scroll px-6 scroll-stable minimal-scrollbar pb-1">
        <div v-for="(part, index) in shortcutKeys" :key="index" class="flex flex-col gap-5">
          <div v-for="(categories, categoryIndex) in part" :key="categoryIndex" class="w-full flex flex-col gap-2">
            <h2 class="text-sm font-semibold">{{ categories.category }}</h2>
            <div class="divide-y divide-gray-200 flex flex-col gap-2">
              <div v-for="item in categories.items" :key="item.key" class=" flex justify-between items-center gap-3 pt-2">
                <p class="text-gray-700 text-sm">{{ item.description?.name }}</p>
                <div class="flex gap-1">
                  <UKbd v-for="shortcut in item.description?.shortcuts" :key="shortcut" size="md">
                    <template v-if="shortcut ==='meta'">
                      {{ metaSymbol }}
                    </template>
                    <template v-else>
                      {{ shortcut }}
                    </template>
                  </UKbd>
                  <template v-if="item.description?.alternative">
                    /
                    <UKbd v-for="shortcut in item.description?.alternative" :key="shortcut" size="md">
                      <template v-if="shortcut ==='meta'">
                        {{ metaSymbol }}
                      </template>
                      <template v-else>
                        {{ shortcut }}
                      </template>
                    </UKbd>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import { ShortcutCategory, ShortcutKeys } from '#core/shortcut_keys_constants'

const { metaSymbol } = useShortcuts()
const isOpen = ref(false)
const shortcutKeys = [
  [
    {
      category: ShortcutCategory.GENERAL,
      items: ShortcutKeys.filter((key) => key.category === ShortcutCategory.GENERAL && key.description) || []
    },
    {
      category: ShortcutCategory.LIST,
      items: ShortcutKeys.filter((key) => key.category === ShortcutCategory.LIST && key.description) || []
    }
  ],
  [
    {
      category: ShortcutCategory.CARD,
      items: ShortcutKeys.filter((key) => key.category === ShortcutCategory.CARD && key.description) || []
    },
  ]
]

const bus = useEventBus('toggle-shortcut-key-board')
const busListener = () => {
  isOpen.value = true
}

onMounted(() => {
  bus.on(busListener)
})

onUnmounted(() => {
  bus.off(busListener)
})
</script>
