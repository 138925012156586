const $useNotificationSharedState = () => {
  const unreadNotificationCount = useState<number>('unreadNotificationCount', () => 0)

  const setUnreadNotificationCount = (payload: number) => {
    unreadNotificationCount.value = payload
  }

  const hasUnreadNotifications = computed(() => unreadNotificationCount.value > 0)

  return {
    hasUnreadNotifications,
    unreadNotificationCount,
    setUnreadNotificationCount,
  }
}

export const useNotificationSharedState = createSharedComposable(
  $useNotificationSharedState
)
