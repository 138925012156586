/* eslint-disable @typescript-eslint/no-explicit-any */
export type RequestId = number

export interface BaseServerMessage {
  readonly reqid: number
}

export interface ServerPongMessage {
  readonly type: 'message'
  readonly si?: string
  readonly message: 'pong'
}

export interface ServerCustomMessage {
  readonly type: 'custom'
  readonly si?: string
  readonly payload: {
    action: string
    [key: string]: any
  }
}

export enum EventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export interface ServerNotifyMessage {
  readonly type: 'model'
  readonly si?: string
  readonly payload: {
    action: 'ADD' | 'UPDATE' | 'DELETE' | 'FORCE_ADD'
    model: {
      id: string
      __typename: string
      [key: string]: any
    }
  }
}

export interface ServerNotificationMessage {
  readonly type: 'notification'
  readonly si?: string
  readonly payload: {
    id: string
    __typename: string
    [key: string]: any
  }
}

export type ServerNotifyMessagePayload = ServerNotifyMessage['payload']

export type ServerCustomMessagePayload = ServerCustomMessage['payload']

export type ServerMessage = ServerPongMessage | ServerNotifyMessage | ServerCustomMessage | ServerNotificationMessage

export type EventMessageListener = (message: ServerMessage) => void

export type EventConnecting = 'connecting'
export type EventOpened = 'opened'
export type EventConnected = 'connected'
export type EventMessage = 'message'
export type EventClosed = 'closed'
export type EventError = 'error'

export type SocketEvent =
  | EventConnecting
  | EventOpened
  | EventConnected
  | EventMessage
  | EventClosed
  | EventError

export type EventConnectingListener = () => void
export type EventOpenedListener = (socket: WebSocket) => void
export type EventConnectedListener = (socket: WebSocket) => void
export type EventErrorListener = (error: unknown) => void
export type EventClosedListener = (event: CloseEvent) => void

export type EventListener<E extends SocketEvent> = E extends EventConnecting
  ? EventConnectingListener
  : E extends EventOpened
  ? EventOpenedListener
  : E extends EventConnected
  ? EventConnectedListener
  : E extends EventMessage
  ? EventMessageListener
  : E extends EventClosed
  ? EventClosedListener
  : E extends EventError
  ? EventErrorListener
  : never

export enum MessageType {
  Ping = 'ping',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum WebSocketReadyState {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}
