<template>
  <div>
    <UModal v-model="needShowInactiveModal" :prevent-close="true">
      <UCard class="text-center">
        <img
          preload
          class="w-[280px] mx-auto"
          src="/assets/images/billing-warning.png"
        />
        <h1 class="text-xl font-semibold mb-1">{{ inactiveModalTitle }}</h1>

        <p class="w-[450px] text-sm mb-4">
          Pick a plan that's right for your team, and keep collaborating! <br>
          <span v-if="!can('settings.general.manage_billing')">
            You don't have permission to manage billing, <br>
            please contact your admin
          </span>
        </p>
        <UButton
          v-if="can('settings.general.manage_billing')"
          label="See all plans"
          @click="showSelectPackage"
        />
        <UButton
          v-else
          label="Contact your admin"
          :disabled="true"
        />
      </UCard>
    </UModal>
    <UCard
      v-if="needShowAddBilling" class="mx-3" :ui="{ body: {
        padding: 'px-3 py-3 sm:p-3'
      } }"
    >
      <p class="text-sm font-medium mb-2.5">
        <template v-if="subscription && isPastDueStatus(subscription?.status)">
          <b>Past due subscription!</b> Update your payment method to avoid service disruption.
        </template>
        <template v-else-if="remainingTrialDays > 0">
          {{ remainingTrialDays }} days left in trial
        </template>
        <template v-else>
          <b>Trial ended!</b> Don't lose access - subscribe now to keep your work.
        </template>
      </p>

      <template v-if="subscription && isPastDueStatus(subscription?.status)">
        <UButton
          v-if="!isInBillingPage && can('settings.general.manage_billing')"
          size="sm" label="Update billing info" @click="navigateTo(getBillingUrl())"
        ></UButton>
      </template>
      <ModalPackage v-else>
        <UButton size="sm" block>
          <template v-if="remainingTrialDays">
            Add billing info
          </template>
          <template v-else>
            Subscribe now
          </template>
        </UButton>
      </ModalPackage>
    </UCard>
  </div>
</template>

<script setup lang="ts">
import { SubscriptionStatus } from '#billing/constant'
import { getRemainingDays, isActiveStatus, isPastDueStatus } from '#billing/utils'

const route = useRoute()
const { can } = useWorkspaceAbility()

// Check subscription
const { subscription, showSelectPackage, getBillingUrl } = useWorkspaceSubscription()
const needShowAddBilling = computed(() => {
  if (!subscription.value) {
    return false
  }

  if (isPastDueStatus(subscription.value?.status)) {
    return true
  }

  return subscription.value?.status === SubscriptionStatus.TRIALING && subscription.value?.placeholder
})
// Show inactive subscription modal
const isInBillingPage = computed(() => {
  const billingUrl = getBillingUrl()
  return route.path.startsWith(billingUrl)
})

const needShowInactiveModal = computed(() => {
  if (subscription.value && !isActiveStatus(subscription.value?.status) && !isInBillingPage.value) {
    return true
  }

  return false
})

const inactiveModalTitle = computed(() => {
})

const remainingTrialDays = computed(() => {
  if (subscription.value) {
    return getRemainingDays(subscription.value?.trialEnd)
  }

  return 0
})
</script>
