<template>
  <UDropdown
    mode="click"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' }, truncate: 'truncate' }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        truncate
        :ui="{ truncate: 'truncate line-clamp-0' }"
        :label="auth.fullName"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
        data-test="user-profile-dropdown"
      >
        <template #leading>
          <Avatar :id="auth.id" :src="auth.photo" :name="auth.fullName" size="2xs" />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left w-full">
        <p class="truncate w-full font-medium text-gray-900 dark:text-white">
          {{ auth.fullName }}
        </p>
        <p class="truncate w-full">{{ auth.email }}</p>
      </div>
    </template>
  </UDropdown>
  <KeyboardShortcutsBoard />
</template>

<script setup lang="ts">
import { profileBaseUrl } from '#auth/utils'

const { auth } = storeToRefs(useAuthStore())
const { currentWorkspace } = useWorkspaceSharedState()
const runtimeConfig = useRuntimeConfig()

const helpItems = [
  {
    label: 'FAQs',
    icon: 'heroicons:book-open',
    to: 'https://help.leanbase.com/en/articles/17-faqs',
    click: (e) => {
      // Open new tab
      window.open('https://help.leanbase.com/en/articles/17-faqs', '_blank')
      e.preventDefault()
    }
  },
  {
    label: 'Help Center',
    icon: 'i-heroicons-question-mark-circle',
    to: 'https://help.leanbase.com',
    click: (e) => {
      // Open new tab
      window.open('https://help.leanbase.com', '_blank')
      e.preventDefault()
    }
  }
]

if (runtimeConfig.public.gleapApiKey) {
  const menuItem = {
    label: 'Chat with Leanbase',
    icon: 'heroicons:chat-bubble-oval-left-ellipsis',
    click: () => {
      showGleapSupport()
    }
  }
  helpItems.push(menuItem)

  // onMounted(() => {
  //   const feedbackButton = document.querySelector('.bb-feedback-button')
  //   if (feedbackButton) {
  //     feedbackButton.style.display = 'none'
  //   }
  // })
}

const showGleapSupport = () => {
  // Find .bb-feedback-button and change css to show it
  const feedbackButton = document.querySelector('.bb-feedback-button')
  if (feedbackButton) {
    feedbackButton.setAttribute('style', 'display: flex !important;')
  }

  window.Gleap.open()
}

const items = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    {
      label: 'Profile',
      icon: 'i-heroicons-user-circle',
      to: profileBaseUrl(currentWorkspace.value.handle) + '/settings',
    },
    {
      label: 'Keyboard shortcuts',
      icon: 'i-quill:command',
      click: () => {
        const { emit } = useEventBus('toggle-shortcut-key-board')
        emit()
      }
    }
  ],
  [
    ...helpItems
  ],
  [
    {
      label: 'Sign out',
      icon: 'i-heroicons-arrow-left-on-rectangle',
      to: '/logout',
    },
  ],
])
</script>
