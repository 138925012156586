import { uniq } from 'lodash-es'
import {
  type ServerMessage,
  type SocketEvent,
  type EventListener,
  type RequestId,
  WebSocketReadyState,
  MessageType,
} from '#core/types/packages/socket'

export const createSocketConnection = (options: {
  url: string | (() => string)
  socketId: string
  accessToken: string
  on?: Partial<{ [event in SocketEvent]: EventListener<event> }>
  shouldRetry?: (event: CloseEvent) => boolean
  retryWait?: (event: CloseEvent) => Promise<unknown>
}) => {
  const { url, socketId, accessToken, on, shouldRetry, retryWait } = options

  let reqid: RequestId = 1
  let ws: WebSocket | null = null
  let buffer: string[] = []
  let isSelfClosed = false
  let subscribers: string[] = []

  /**
   * An event emitter that is exposed through the public API of createSocketConnection. This allows the consumer to
   * subscribe to key events and messages. e.g:
   *
   * const socketConnection = createSocketConnection({
   *   on: {
   *     connected: () => {
   *       logger.info('Successfully connected to socket!');
   *     }
   *   }
   * });
   *
   */
  const emitter = (() => {
    const listeners: { [event in SocketEvent]: EventListener<event>[] } = {
      connecting: on?.connecting ? [on.connecting] : [],
      opened: on?.opened ? [on.opened] : [],
      connected: on?.connected ? [on.connected] : [],
      message: on?.message ? [on.message] : [],
      closed: on?.closed ? [on.closed] : [],
      error: on?.error ? [on.error] : [],
    }

    return {
      on<E extends SocketEvent>(event: E, listener: EventListener<E>) {
        const l = listeners[event] as EventListener<E>[]
        l.push(listener)
        return {
          unsubscribe: () => {
            l.splice(l.indexOf(listener), 1)
          },
        }
      },
      emit<E extends SocketEvent>(
        event: E,
        ...args: Parameters<EventListener<E>>
      ) {
        for (const listener of [...listeners[event]]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          listener(...args)
        }
      },
    }
  })()

  const parseMessage = (data: unknown): ServerMessage => {
    return typeof data === 'string' && data !== '' ? JSON.parse(data) : data
  }

  const _send = (data: string) => {
    if (ws?.readyState !== WebSocketReadyState.Open) {
      return buffer.push(data)
    }

    try {
      ws?.send(data)
    } catch (error) {
      buffer.push(data)
      ws?.close()
    }
  }

  const send = (data: {
    type: MessageType
    payload?: Record<string, unknown> | unknown
  }) => {
    const message = JSON.stringify({
      ...data,
      reqid: data.type === MessageType.Ping ? 0 : reqid++,
    })
    if (data.type === MessageType.Subscribe) {
      subscribers.push(message)
    }

    if (data.type === MessageType.Unsubscribe) {
      subscribers = subscribers.filter((sub) => sub !== message)
    }

    _send(message as string)
  }

  const connect = async () => {
    isSelfClosed = false
    emitter.emit('connecting')
    const _url = typeof url === 'string' ? url : url()
    ws = new WebSocket(decodeURIComponent(`${_url}?si=${socketId}`), ['access-token', accessToken])
    const onOpen = () => {
      emitter.emit('opened', ws!)
      send({ type: MessageType.Ping })
    }

    const onMessage = (event: MessageEvent) => {
      if (ws?.readyState !== WebSocketReadyState.Open) {
        return
      }

      const message = parseMessage(event.data)
      if (message.type === 'message' && message.message === 'pong') {
        emitter.emit('connected', ws!)
        flushQueues()
      }

      emitter.emit('message', message)
    }

    const onError = (event: Event) => {
      emitter.emit('error', event)
    }

    const onClose = (event: CloseEvent) => {
      emitter.emit('closed', event)
      if (isSelfClosed || !shouldRetry?.(event)) {
        return
      }

      if (!retryWait) {
        return connect()
      }

      return retryWait(event).then(() => connect())
    }

    ws.onopen = onOpen
    ws.onmessage = onMessage
    ws.onerror = onError
    ws.onclose = onClose
  }

  const flushQueues = () => {
    const queues = uniq([...buffer, ...subscribers])
    queues.map((queue) => _send(queue))
    subscribers = []
  }

  connect()

  return {
    socketId,
    buffer,
    isSelfClosed: () => isSelfClosed,
    send,
    on: emitter.on,
    reconnect: connect,
    terminate: (code?: number, reason?: string) => {
      ws?.close(code, reason)
      subscribers = []
      buffer = []
      isSelfClosed = true
    },
  }
}
