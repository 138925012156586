<template>
  <LazyNotificationPopover>
    <UButton
      variant="ghost"
      color="gray"
      class="flex gap-2 p-2 items-center hover:bg-white w-full"
      data-test="notification-button"
    >
      <UChip
        v-if="hasUnreadNotifications"
        color="red"
        inset
        data-test="new-notification-indicator"
      >
        <Icon name="heroicons:bell" class="size-6 text-gray-600" />
      </UChip>
      <Icon v-else name="heroicons:bell" class="size-6 text-gray-600" />

      <span class="text-gray-600 text-sm font-medium">Notification</span>
    </UButton>
  </LazyNotificationPopover>
</template>

<script lang="ts" setup>
const { currentWorkspace } = useWorkspaceSharedState()
const { hasUnreadNotifications, setUnreadNotificationCount } = useNotificationSharedState()
const { result, onResult, load } = useLazyUnreadNotificationsCountQuery(currentWorkspace.value?.id)

onResult(() => {
  if (result.value) {
    setUnreadNotificationCount(result.value.unreadNotificationsCount || 0)
  }
})

onMounted(() => {
  load()
})
</script>
