import type { NotificationAction } from '#ui/types'
import { createSocketConnection } from '#core/packages/socket'

export const $useSocket = () => {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()
  const toast = useToast()
  const { client } = useApolloClient()
  const { isLoggedIn, accessToken } = useAuthStore()
  const { isOnline } = useNetwork()

  const socket = ref<ReturnType<typeof createSocketConnection>>()

  const maxDelayMs: number = 30000
  const initialDelayMs: number = 1000
  const retryAfterErrorMins: number = 5
  let retryDelay: number = initialDelayMs
  let severeErrorTimeout: NodeJS.Timeout | null = null
  let isSevereError: boolean = false
  let hasFailed: boolean = false
  let isManualConnect: boolean = false

  const removeToasts = () => {
    toast.remove('socket-server-error-toast')
    toast.remove('socket-retry-toast')
  }

  const reset = (): void => {
    removeToasts()
    retryDelay = initialDelayMs
    if (severeErrorTimeout) {
      clearTimeout(severeErrorTimeout)
      severeErrorTimeout = null
    }

    isSevereError = false
    hasFailed = false
    isManualConnect = false
  }

  const manualReconnectAction: NotificationAction = {
    label: 'Retry now',
    click: () => {
      isManualConnect = true
      socket.value?.reconnect()
    },
  }

  const calculateBackoffWithJitter = (): number => {
    const jitter: number = Math.random() * 0.5
    return Math.min(retryDelay * (1 + jitter), maxDelayMs)
  }

  const initialize = () => {
    logger.info('[Socket] Initializing...', { isLoggedIn })

    if (isLoggedIn && accessToken) {
      socket.value = createSocketConnection({
        url: config.public.socketApiEndpoint,
        socketId: nuxtApp.$socketId,
        accessToken,
        shouldRetry: () => {
          if (isSevereError) {
            toast.remove('socket-retry-toast')
            toast.add({
              id: 'socket-server-error-toast',
              color: 'red',
              title: 'Connection failed',
              description: `Retrying in ${retryAfterErrorMins} minutes... or click to reconnect.`,
              timeout: 0,
              actions: [manualReconnectAction],
            })
            return false
          }

          hasFailed = true
          const retryTime = retryDelay / 1000
          toast.add({
            id: 'socket-retry-toast',
            color: 'yellow',
            title: 'Connection failed',
            description: `Retrying in ${retryTime} seconds... or click to reconnect.`,
            timeout: 0,
            actions: [manualReconnectAction],
          })

          return true
        },
        retryWait: async () => {
          const waitTime: number = calculateBackoffWithJitter()
          retryDelay = Math.min(retryDelay * 2, maxDelayMs)
          await sleep(waitTime)
        },
        on: {
          connected: () => {
            logger.info('[Socket] Connected')
            if (hasFailed || isManualConnect) {
              client.refetchQueries({
                include: 'active',
              })
              toast.add({
                color: 'green',
                title: 'Connected',
                description: 'Connected to Leanbase sync server.',
                timeout: 5000,
              })
            }

            reset()
          },
          closed: () => {
            logger.info('[Socket] Disconnected', {
              isSelfClose: socket.value?.isSelfClosed(),
            })
          },
          error: (error) => {
            logger.info('[Socket] Error', error)
            removeToasts()
            isSevereError = true
            severeErrorTimeout && clearTimeout(severeErrorTimeout)
            severeErrorTimeout = setTimeout(() => {
              logger.log('[Socket] Reconnecting to server...')
              isSevereError = false
              retryDelay = initialDelayMs
              socket.value?.reconnect()
            }, retryAfterErrorMins * 60 * 1000)
          },
        },
      })
    }
  }

  watch(
    () => isOnline.value,
    (online) => {
      if (online) {
        socket.value?.reconnect()
        client.refetchQueries({
          include: 'active',
        })
      }
    }
  )

  return {
    socket,
    initialize,
    reset,
  }
}

export const useSocket = createSharedComposable($useSocket)
